/* src/ui/layout/footer/Footer.module.css */
.footerContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  z-index: 900;
}

@media (min-width: 768px) {
  .footerContainer {
    justify-content: flex-start;
  }
}

@media (min-width: 1224px) {
  .footerContainer {
    padding: 40px 80px;
    max-width: 60%;
    margin: auto;
    justify-content: space-between;
  }
}

.footerLine {
  height: 1px;
  margin: 10px 0;
  background-color: var(--secondaryColor);
  z-index: 1000;
}

/* About */
.aboutContainer {
  padding: 20px;
}

@media (min-width: 400px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 1600px) {
  .container {
    width: 30%;
    margin: 0 auto;
    min-width: 25rem;
  }
}

.logoTitleContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  margin: 0 0 15px auto;
}

.footerAboutTitle {
  margin: 0 auto 0 0;
}

p.center {
  text-align: center;
}

/* LI  */
.footerSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 200px;
}

.list {
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listItem {
  width: 14rem;
  font-size: 1.1rem;
  margin: 8px auto;
}

.img-placeholder {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9; /* exemple de ratio */
  background-color: #e0e0e0; /* Couleur de fond pour le placeholder */
}

/* foot */
.foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.6rem;
}

.footerLegal {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  cursor: pointer;
}

.link:hover,
.link:focus {
  text-decoration: underline;
}
