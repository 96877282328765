:root {
  --cardBg: #7cc2de;
  --cardTxt: #242a58;
}

footer {
  text-align: center;
}

aside {
  text-align: right;
}

p.center {
  text-align: center;
}

p.left {
  text-align: left;
}

p.right {
  text-align: right;
}

ul {
  list-style: none;
}

li {
  margin-top: 0.7rem;
}

button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  outline: inherit;
}

img {
  margin: auto;
}

button,
svg,
a {
  cursor: pointer;
  line-height: 1.6;
}

.img-wrap {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  border-radius: 15px;
  display: flex;
  object-fit: cover;
}

.svgIcon {
  width: 24px;
  height: 24px;
  fill: var(--text);
}
.svgInfo {
  fill: var(--text);
  stroke: var(--text);
}

/*
@media (min-width: 310px) {
}

@media (min-width: 480px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
}

@media (min-width: 1440px) {
}

@media (min-width: 1920px) {
}
*/

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.bounceAnimation {
  animation: bounce 2s infinite;
}

/* Animation rotateIn */
@keyframes rotateIn {
  from {
    transform: rotate(0deg); /* Départ à 0 degré */
  }
  to {
    transform: rotate(180deg); /* Fin à 180 degrés */
  }
}
.rotateIn {
  animation: rotateIn 1s forwards; /* 1s pour la durée de l'animation, forwards pour maintenir l'état final */
}

/* Animation rotateOut */
@keyframes rotateOut {
  from {
    transform: rotate(180deg); /* Départ à 180 degrés */
  }
  to {
    transform: rotate(0deg); /* Retour à 0 degré */
  }
}
.rotate-out {
  animation: rotateOut 1s forwards;
}

/* Animation grow */
@keyframes grow {
  from {
    transform: scale(0); /* Départ réduit à zéro */
    opacity: 0; /* Complètement transparent au début */
  }
  to {
    transform: scale(1); /* Fin à taille normale */
    opacity: 1; /* Complètement visible à la fin */
  }
}

.grow {
  animation: grow 1s forwards; /* 1s pour la durée de l'animation, forwards pour maintenir l'état final */
}

@keyframes shrink {
  from {
    transform: scale(1); /* Départ à taille normale */
    opacity: 1; /* Complètement visible au début */
  }
  to {
    transform: scale(0); /* Fin réduite à zéro */
    opacity: 0; /* Complètement transparent à la fin */
  }
}

/* Animation fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

/* Animation fadeOut */
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.fadeOut {
  animation: fadeOut 0.3s ease-in-out;
}
