/* Navbar.module.css */

/* Mobile-first base styles */
.navbar {
  display: flex;
  position: sticky;
  top: 0;
  background: var(--layoutBg);
  padding: 0.3rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  font-size: 0.9rem;
}

.navItem,
.rightSection,
.hamburger,
.logo {
  display: flex;
  align-items: center;
}

.navLinks {
  display: none;
}

.hamburger {
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.2s ease-in-out;
}

.hamburger:hover {
  transform: scale(1.1);
}

.title {
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}

.textTitle {
  display: none;
}

.link {
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}

.aside {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  z-index: 1001;
}

/* Media Queries */
@media (min-width: 310px) {
  .textTitle {
    display: flex;
  }
}

@media (min-width: 600px) {
  .navLinks {
    display: flex;
    flex-direction: row;
    margin-right: auto;
  }
}

@media (min-width: 720px) {
  .navbar {
    font-size: 1.1rem;
  }
}

@media (min-width: 1250px) {
  .navbar {
    font-size: 1.2rem;
  }

  .rightSection {
    gap: 15px;
  }
}

/* ServicesDropdown.module.css */
.dropdown {
  position: static;
  top: auto;
  left: auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  z-index: 999;
  display: none;
  animation: fadeIn 0.3s ease-in-out;
}

.dropdown.show {
  display: block;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropdown li {
  display: block;
}

.dropdown a {
  padding: 0.1rem 1rem;
  white-space: nowrap;
  display: block;
  background: var(--layoutBg);
  border-radius: 5px;
  text-align: center;
}

.dropdown a:hover {
  background-color: var(--hoverBg);
}

@media (min-width: 600px) {
  .dropdown {
    position: absolute;
    top: 1.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 400px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .dropdown ul {
    flex-direction: row;
    justify-content: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* AsideNavMenu.module.css */
.aside {
  position: fixed;
  right: 0;
  top: 3rem;
  font-size: 1.2rem;
  max-width: 100%;
  max-height: 70vh;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 900;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.asideOpen {
  transform: translateX(0);
}

.linkContainer {
  display: flex;
  flex-direction: column;
  margin: 0 35px 0 auto;
  padding: 20px;
  align-items: flex-end;
}

.linkB {
  margin-bottom: 10px;
}
