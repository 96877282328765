/* WhatsAppLink.module.css */
.linkStyle {
  position: fixed;
  right: 10px;
  bottom: 20vh;
  z-index: 900;
}

@media (min-width: 768px) {
  .linkStyle {
    right: 30px;
  }
}

.iconStyle {
  width: 50px;
  height: 50px;
  fill: #00ff00;
  stroke: green;
  stroke-width: 1px;
}
