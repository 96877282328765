/* NavButton.module.css */
.container {
  position: relative;
  display: inline-block;
}

.input {
  background: var(--layoutBg);
  width: 70px;
  padding: 5px;
  color: var(--text);
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

@media (min-width: 640px) {
  .input {
    width: 100px;
    font-size: 1rem;
  }
}

@media (min-width: 720px) {
  .input {
    padding: 10px;
    width: 150px;
    font-size: 1.1rem;
  }
}

@media (min-width: 1250px) {
  .input {
    width: 100%;
    font-size: 1.2rem;
  }
}

.input:focus {
  outline: none;
  border-color: var(--cardBg);
}

.button {
  width: 100%;
  margin: 0;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
}

.button:hover {
  background-color: var(--cardBg);
  color: var(--text);
}

.dropdown {
  position: absolute;
  font-size: 0.8rem;
  top: calc(100% + 4px);
  left: 0;
  background: var(--layoutBg);
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  min-width: 130px;
  z-index: 1000;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 720px) {
  .dropdown {
    font-size: 1.1rem;
  }
}
