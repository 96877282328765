/* src/ui/layout/Remounted.module.css */
.button {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  background-color: #1e88e5;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 1000;
  font-size: 1.5rem;
  text-align: center;
  line-height: 50px;
  opacity: 0.8;
  transition:
    opacity 0.3s,
    transform 0.3s;
  will-change: transform, opacity;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.button:hover {
  opacity: 1;
  transform: translateY(-0.15em);
}
